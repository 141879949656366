import React, { useState, useRef, useEffect } from "react";
import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, IconButton, Link, Modal, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from '@mui/icons-material/Close';
import textfieldData from "./TextfieldData";
import Confidentiality from "./Confidentiality";
import Clarification from "./Clarification";
import validationFunctions from "./ValidationFunctions";
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
import "./Form.css";

export default function Form(props) {
    const { t } = useTranslation();
    const [didFormSend, setDidFormSend] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [modalConfidentialityOpen, setModalConfidentialityOpen] = useState(false);
    const [modalClarificationOpen, setModalClarificationOpen] = useState(false);
    const [btnErrText, setBtnErrText] = useState("");
    const [formData, setFormData] = useState({
        ipAddress: "",
        vulnerabilityDate: "",
        asset: "",
        cwe: "",
        userName: "",
        userEmail: "",
        title: "",
        summary: "",
        payload: "",
        reproduce: "",
        material: "",
        impact: "",
        remediation: ""
    });
    const hasError = useRef({
        ipAddress: false,
        vulnerabilityDate: false,
        asset: false,
        cwe: false,
        userName: false,
        userEmail: false,
        title: false,
        summary: false,
        payload: false,
        reproduce: false,
        material: false,
        impact: false,
        remediation: false
    });
    const isVisited = useRef({
        ipAddress: false,
        vulnerabilityDate: false,
        asset: false,
        cwe: false,
        userName: false,
        userEmail: false,
        title: false,
        summary: false,
        payload: false,
        reproduce: false,
        material: false,
        impact: false,
        remediation: false
    });
    const [validationTexts, setValidationTexts] = useState({
        ipAddress: t("success_ip"),
        vulnerabilityDate: "",
        asset: "",
        cwe: "",
        userName: "",
        userEmail: t("success_email"),
        title: "",
        summary: "",
        payload: "",
        reproduce: "",
        material: "",
        impact: "",
        remediation: ""
    });

    useEffect(() => {
        let errorState = false;
        for (let key in hasError.current) {
            if (hasError.current.hasOwnProperty(key) && hasError.current[key]) {
                errorState = true;
                break;
            }
        }
        if (errorState) {
            setBtnErrText(t("err_validation"))
        }
        else {
            setBtnErrText("")
        }
    }, [validationTexts]);

    const handleChange = (event) => {
        if (isVisited.current[event.target.name]) {
            handleValidation(event.target.name, event.target.value)
        }
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        });
    }

    const handleValidation = (name, value) => {
        const validationResult = validationFunctions[name](value)
        hasError.current[name] = !validationResult.result
        setValidationTexts(prevData => {
            return {
                ...prevData,
                [name]: t(validationResult.message)
            }
        });
    }

    const handleBlur = (event) => {
        isVisited.current[event.target.name] = true;
        const trimmedValue = event.target.value.trim();
        const trimMiddleValue = trimmedValue.replace(/\s\s+/g, ' '); //remove extra middle spaces
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: trimMiddleValue
            }
        });
        handleValidation(event.target.name, trimMiddleValue)
    }

    const checkAllValidations = () => {
        for (let key in isVisited.current) {
            if (isVisited.current.hasOwnProperty(key)) {
                isVisited.current[key] = true;
            }
        }
        for (let key in formData) {
            if (formData.hasOwnProperty(key)) {
                handleValidation(key, formData[key])
            }
        }
        for (let key in hasError.current) {
            if (hasError.current.hasOwnProperty(key) && hasError.current[key]) {
                return false;
            }
        }
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (checkAllValidations()) {
            setBackdropOpen(true);
            if (!didFormSend) {
                setDidFormSend(true);
                fetch(`${process.env.REACT_APP_API_BASE_URL}/report_form/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...formData, score: props.score }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(t("err_network"));
                        }
                        else {
                            setBackdropOpen(false);
                            Swal.fire({
                                icon: 'success',
                                title: t("report_success_title"),
                                text: t("report_success_text")
                            })
                        }
                    })
                    .catch(error => {
                        setDidFormSend(false);
                        setBackdropOpen(false);
                        Swal.fire({
                            icon: 'error',
                            title: t("report_error_title"),
                            text: t("report_error_text")
                        })
                    });
            }
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => setBackdropOpen(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                open={modalConfidentialityOpen}
                onClose={() => setModalConfidentialityOpen(false)}>
                <Box className="agreement--window" sx={{ bgcolor: 'background.paper' }}>
                    <IconButton className="agreement--close--icon" onClick={() => setModalConfidentialityOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                    <Confidentiality />
                </Box>
            </Modal>
            <Modal
                open={modalClarificationOpen}
                onClose={() => setModalClarificationOpen(false)}>
                <Box className="agreement--window" sx={{ bgcolor: 'background.paper' }}>
                    <IconButton className="agreement--close--icon" onClick={() => setModalClarificationOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                    <Clarification />
                </Box>
            </Modal>
            <Box component="form" onSubmit={handleSubmit}>
                <div className="score--field">
                    <TextField
                        className="small--textfield"
                        value={props.score}
                        disabled={true}
                        label={t('label_score')}
                        variant="outlined" />
                    {!props.allowSubmit &&
                        <Alert
                            className="alert--report"
                            severity="error">
                            <a href="#step5">{t("warning_calculate")}</a>
                        </Alert>
                    }
                </div>
                {
                    textfieldData().map((textfieldProps) => {
                        return (
                            <div
                                className="textfield--container"
                                key={textfieldProps.name}>
                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    value={formData[textfieldProps.name]}
                                    error={hasError.current[textfieldProps.name]}
                                    helperText={validationTexts[textfieldProps.name]}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    {...textfieldProps}
                                />
                            </div>
                        );
                    })
                }
                <div className="checkbox--field">
                    <Checkbox required />
                    <span>
                        {t("agreement_accept")}
                        <Link
                            onClick={() => setModalConfidentialityOpen(true)}
                            underline="hover">
                            {t("agreement_name")}
                        </Link>
                    </span>
                </div>
                <div className="clarification--text">
                    {t("clarification_paragraph")}
                    <Link
                        onClick={() => setModalClarificationOpen(true)}
                        underline="hover">
                        {t("clarification_link")}
                    </Link>
                </div>
                <div className="report--bot--field">
                    <span className="report--err-message">{btnErrText}</span>
                    <div className="report--button--container">
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon />}
                            disabled={!props.allowSubmit || didFormSend}>
                            {t("report_label")}
                        </Button>
                        <br /><br />
                    </div>
                </div>
            </Box>
        </>
    );
}
