import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Layout from "./Routes/Layout/Layout";
import Summary from "./Routes/Summary/Summary";
import About from "./Routes/About/About";
import Scope from "./Routes/Scope/Scope";
import Ranking from "./Routes/Ranking/Ranking";
import Report from "./Routes/Report/Report";
import NotFound from "./Routes/NotFound/NotFound";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Summary />} />
            <Route path="about" element={<About />} />
            <Route path="ranking" element={<Ranking />} />
            <Route path="scope" element={<Scope />} />
            <Route path="report" element={<Report />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
