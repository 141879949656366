import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Calculator from "../../Components/Calculator/Calculator";
import Form from "../../Components/Form/Form";

export default function Ranking() {
    const { t } = useTranslation();
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [score, setScore] = useState(0);
    const handleAllowSubmit = (value) => setAllowSubmit(value);
    const handleSetScore = (value) => setScore(value);

    return (
        <div className="content--main">
            <div className="cvss--content">
                <h3>{t("cvss_calc")}</h3>
                <div className="content--list">
                    <p className="calculator--top--text">
                        {t("cvss_desc")}
                    </p>
                    <Calculator
                        allowSubmit={allowSubmit}
                        handleAllowSubmit={handleAllowSubmit}
                        setScore={handleSetScore} />
                </div>
            </div>
            <div className="report--content">
                <h3>{t("report_header")}</h3>
                <div className="content--list">
                    <p>
                        {t("report_desc")}
                    </p>
                    <Form
                        allowSubmit={allowSubmit}
                        score={score} />
                </div>
            </div>
        </div>

    );
}