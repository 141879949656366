import { useRef, useEffect } from 'react'
import "./Canvas.css";

function Canvas(props) {

    const canvasRef = useRef(null);
    const canvasContainerRef = useRef(null);
    var stars = [], // Array that contains the stars
        FPS = 60, // Frames per second
        mouse = {
            x: 0,
            y: 0
        };  // mouse location

    function distance(point1, point2) {
        var xs = 0;
        var ys = 0;

        xs = point2.x - point1.x;
        xs = xs * xs;

        ys = point2.y - point1.y;
        ys = ys * ys;

        return Math.sqrt(xs + ys);
    }

    useEffect(() => {
        if (canvasContainerRef.current) {
            var canvas = canvasRef.current
            var ctx = canvas.getContext('2d')
            canvas.width = canvasContainerRef.current.offsetWidth;
            canvas.height = 510;
            var starCount = Math.floor(canvasContainerRef.current.offsetWidth / 6)
            let animationFrameId

            // Push stars to array

            for (var i = 0; i < starCount; i++) {
                stars.push({
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    radius: Math.random() * 1 + 1,
                    vx: Math.floor(Math.random() * 50) - 25,
                    vy: Math.floor(Math.random() * 50) - 25
                });
            }

            // Draw the scene

            function draw() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                ctx.globalCompositeOperation = "lighter";

                for (var i = 0, x = stars.length; i < x; i++) {
                    var s = stars[i];

                    ctx.fillStyle = "#000";
                    ctx.beginPath();
                    ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.fillStyle = 'black';
                    ctx.stroke();
                }

                ctx.beginPath();
                for (let i = 0, x = stars.length; i < x; i++) {
                    var starI = stars[i];
                    ctx.moveTo(starI.x, starI.y);
                    if (distance(mouse, starI) < 150) ctx.lineTo(mouse.x, mouse.y);
                    for (let j = 0, x = stars.length; j < x; j++) {
                        var starII = stars[j];
                        if (distance(starI, starII) < 150) {
                            ctx.lineTo(starII.x, starII.y);
                        }
                    }
                }
                ctx.lineWidth = 0.05;
                ctx.strokeStyle = 'black';
                ctx.stroke();
            }
            // Update star locations

            function update() {
                for (var i = 0, x = stars.length; i < x; i++) {
                    var s = stars[i];
                    s.x += s.vx / FPS;
                    s.y += s.vy / FPS;
                    if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
                    if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
                }
            }
            // Update and draw
            function tick() {
                draw();
                update();
                animationFrameId = requestAnimationFrame(tick);
            }
            function handleMouseMove(e) {
                var bounds = e.target.getBoundingClientRect();
                mouse.x = e.pageX - bounds.left - document.documentElement.scrollLeft;
                mouse.y = e.pageY - bounds.top - document.documentElement.scrollTop;
            }
            canvas.addEventListener('mousemove', handleMouseMove);
            tick();

            return () => {
                canvas.removeEventListener('mousemove', handleMouseMove)
                window.cancelAnimationFrame(animationFrameId)
            }
        }
    })

    return (
        <>
            <div className="canvas--container" ref={canvasContainerRef}>
                <canvas ref={canvasRef} />
            </div>
        </>
    );
}

export default Canvas;
