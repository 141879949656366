import { useTranslation } from 'react-i18next';
import "./Scope.css"

export default function Scope() {
    const { t } = useTranslation();

    return (
        <div className="content--main">
            <h3>{t("scope_header")}</h3>
            <div className="content--list">
                {t("scope_desc")}
                <ul className="content--link--list">
                    <li className="list-bg">https://hepsiburada.com</li>
                    <li className="list-bg">https://sanaozel.hepsiburada.com</li>
                    <li className="list-bg">https://giris.hepsiburada.com</li>
                    <li className="list-bg">https://hediyeceklerim.hepsiburada.com</li>
                    <li className="list-bg">https://giris.hepsiburada.com</li>
                    <li className="list-bg">https://checkout.hepsiburada.com</li>
                    <li className="list-bg">https://hesabim.hepsiburada.com</li>
                    <li className="list-bg">https://kuponlarim.hepsiburada.com</li>
                    <li className="list-bg">https://listelerim.hepsiburada.com </li>
                </ul>
            </div>
            <h3>{t("exclusion_header")}</h3>
            <div className="content--list">
                {t("exclusion_desc")}
                <ul className="content--link--list">
                    <li className="list-bg">{t("exclusion_note1")}</li>
                    <li className="list-bg">{t("exclusion_note2")}</li>
                    <li className="list-bg">{t("exclusion_note3")}</li>
                    <li className="list-bg">{t("exclusion_note4")}</li>
                    <li className="list-bg">{t("exclusion_note5")}</li>
                    <li className="list-bg">{t("exclusion_note8")}</li>
                    <li className="list-bg">{t("exclusion_note9")}</li>
                    <li className="list-bg">{t("exclusion_note10")}</li>
                    <li className="list-bg">{t("exclusion_note11")}</li>
                    <li className="list-bg">{t("exclusion_note14")}</li>
                    <li className="list-bg">{t("exclusion_note15")}</li>
                    <li className="list-bg">{t("exclusion_note16")}</li>
                    <li className="list-bg">{t("exclusion_note17")}</li>
                    <li className="list-bg">{t("exclusion_note18")}</li>
                    <li className="list-bg">{t("exclusion_note19")}</li>
                    <li className="list-bg">{t("exclusion_note20")}</li>
                    <li className="list-bg">{t("exclusion_note21")}</li>
                </ul>
            </div>
        </div>
    );
}