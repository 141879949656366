import React from "react";
import { useTranslation } from 'react-i18next';
import EnAgreement from "../../assets/locale/en/ConfidentialityAgreement"
import TrAgreement from "../../assets/locale/tr/ConfidentialityAgreement"

export default function Agreement(props) {
    const { i18n } = useTranslation();
    if (i18n.language === "tr") {
        return <TrAgreement />
    }
    return <EnAgreement />
}