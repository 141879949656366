import { useTranslation } from 'react-i18next';

export default function About() {
    const { t } = useTranslation();

    return (
        <div className="content--main">
            <h3>{t("about_summary_header")}</h3>
            {t("about_summary_desc")}
            <br />
            <br />
            <h3>{t("change_header")}</h3>
            <div className="content--list">
                <p>
                    {t("change_desc")}
                </p>
                <p>
                    {t("change_date")}
                </p>
            </div>
        </div>
    );
}