import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enMainContent from './en/MainContent';
import enCalculatorContent from './en/CalculatorContent';
import enReportContent from './en/ReportContent';
import trMainContent from './tr/MainContent';
import trCalculatorContent from './tr/CalculatorContent';
import trReportContent from './tr/ReportContent';



i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...enMainContent,
          ...enCalculatorContent,
          ...enReportContent
        }
      },
      tr: {
        translation: {
          ...trMainContent,
          ...trCalculatorContent,
          ...trReportContent
        }
      }
    }
  });

export default i18n;