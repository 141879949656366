import React from "react";

export default function Agreement(props) {
    return (
        <div className="WordSection1">
            <p className="MsoNormal" style={{ textAlign: "justify", lineHeight: "150%" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify", lineHeight: "150%" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                <b>
                    <span lang="tr">
                        HEPSİBOUNTY PROGRAMI KAPSAMINDA KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN
                        AYDINLATMA METNİ{" "}
                    </span>
                </b>
            </p>
            <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                <b>
                    <span lang="tr">&nbsp;</span>
                </b>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="tr">
                        Veri Sorumlusu: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; D-Market Elektronik
                        Hizmetler ve Ticaret A.Ş.
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ marginLeft: "108.0pt" }}>
                <span lang="tr">
                    Kuştepe Mah. Mecidiyeköy Yolu Cad. Trump Towers Kule 2 Kat:2 No:12&nbsp;
                    34387 Şişli/İstanbul
                </span>
            </p>
            <p className="MsoNormal" style={{ marginLeft: "108.0pt" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    D-Market Elektronik Hizmetler ve Ticaret A.Ş. <b>(“Hepsiburada”)</b>{" "}
                    olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (“<b>KVKK</b>”) ve
                    ilgili mevzuat uyarınca Hepsibounty Programı kapsamında yürütülen süreçler
                    özelinde kişisel verilerinizin işlenmesi, saklanması ve aktarılması ile
                    ilgili sizleri aydınlatmak amacıyla işbu aydınlatma metnini hazırladık.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="tr">1. Hangi Kişisel Verileriniz İşlenmektedir?</span>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">
                    Hepsibounty Programına katılım süreci kapsamında sizlerin{" "}
                    <b>kimlik bilgileri</b>
                    <i> (ad, soyad)</i>, <b>iletişim bilgileri</b>
                    <i> (e-posta adresi), </i>
                    <b>işlem güvenliği bilgileri</b>
                    <i> (public IP adresi) </i>elektronik formlar vasıtasıyla toplanarak
                    işlenmektedir.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">
                    Söz konusu kişisel verileriniz iş faaliyetlerinin yürütülmesi/denetimi,
                    sözleşme süreçlerinin yürütülmesi, iletişim faaliyetlerinin yürütülmesi,
                    bilgi güvenliği süreçlerinin yürütülmesi, iş süreçlerinin
                    iyileştirilmesine yönelik önerilerin alınması ve değerlendirilmesi,
                    yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla sınırlı
                    olarak işlenmektedir.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    Kimlik, iletişim ve işlem güvenliği bilgileriniz, KVKK’nın 5. maddesinin
                    2. fıkrasının (f) bendi{" "}
                    <i>
                        “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
                        veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
                        olması”{" "}
                    </i>
                    hukuki sebebine dayanılarak işlenecektir.{" "}
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="tr">
                        2. Kişisel Verileriniz Kimlere ve Hangi Amaçlarla Aktarılabilecektir?
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    Kişisel verileriniz,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Yasal düzenlemenin öngördüğü kapsamda faaliyetlerin mevzuata uygun
                    yürütülmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
                    amaçlarıyla <b>Yetkili Kamu Kurum ve Kuruluşları</b>’na
                </span>
                <span className="MsoCommentReference">
                    <span lang="tr" style={{ fontSize: "8.0pt", lineHeight: "115%" }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">aktarılabilecektir.</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="tr">
                        3. İlgili Kişi Olarak Kişisel Verilerinize İlişkin Haklarınız Nelerdir?
                    </span>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">
                    KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin olarak;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">Kişisel verilerinizin işlenip işlenmediğini öğrenme,</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
                    kullanılmadığını öğrenme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü
                    kişileri bilme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların
                    düzeltilmesini isteme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin
                    silinmesini veya yok edilmesini isteme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin
                    silinmesi veya yok edilmesini talep ettiğinizde, bu durumun kişisel
                    verilerinizi aktardığımız üçüncü kişilere bildirilmesini isteme,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
                    edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına
                    itiraz etme ve
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "10.0pt",
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                </span>
                <span lang="tr">
                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
                    uğramanız halinde bu zararın giderilmesini talep etme
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">haklarına sahipsiniz. </span>
            </p>
            <h1 style={{ margin: "0cm", textAlign: "justify" }}>
                <b>
                    <span lang="tr" style={{ fontSize: "11.0pt", lineHeight: "115%" }}>
                        4. İlgili Kişi Olarak Haklarınızı Nasıl Kullanabilirsiniz?
                    </span>
                </b>
            </h1>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz{" "}
                    <a href="https://images.hepsiburada.net/assets/gif/hepsiburada/HB_KVKBasvuruFormu_20210111_2.pdf">
                        <b>
                            <span style={{ color: "#1155CC" }}>İlgili Kişi Başvuru Formu</span>
                        </b>
                    </a>
                    ’<b>nu</b>
                    kullanarak;
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Islak imzalı ve kimlik fotokopisi ile{" "}
                    <i>
                        Kuştepe Mah. Mecidiyeköy Yolu Cad. Trump Towers Kule 2 Kat:2 No:12 34387
                        Şişli/İstanbul
                    </i>
                    adresine göndererek,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Geçerli bir kimlik belgesi ile birlikte Şirketimize bizzat başvurarak,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="tr">
                    Kayıtlı elektronik posta (KEP) adresi ve güvenli elektronik imza ya da
                    mobil imza kullanmak suretiyle{" "}
                    <a href="mailto:dmarket@hs02.kep.tr">
                        <span style={{ color: "#1155CC" }}>dmarket@hs02.kep.tr</span>
                    </a>
                    &nbsp; kayıtlı elektronik posta adresimize göndererek veya
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "10.0pt",
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                </span>
                <span lang="tr">
                    Şirketimize daha önce bildirilen ve sistemimizde kayıtlı bulunan
                    elektronik posta adresinizden{" "}
                    <a href="mailto:kvk@hepsiburada.com">
                        <span style={{ color: "#1155CC" }}>kvk@hepsiburada.com</span>
                    </a>
                    &nbsp; adresimize göndererek
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">Hepsiburada’ya iletebilirsiniz.</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "12.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify"
                }}
            >
                <span lang="tr">
                    Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca, İlgili
                    Kişi’nin, başvurusunda isim, soyisim, başvuru yazılı ise imza, T.C. kimlik
                    numarası (başvuruda bulunan kişinin yabancı olması halinde uyruğu,
                    pasaport numarası veya varsa kimlik numarası), tebligata esas yerleşim
                    yeri veya iş yeri adresi, varsa bildirime esas elektronik posta adresi,
                    telefon numarası ve faks numarası ile talep konusuna dair bilgilerin
                    bulunması zorunludur.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "12.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify"
                }}
            >
                <span lang="tr">
                    İlgili Kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve
                    kullanmayı talep ettiği hakka ilişkin açıklamaları içeren başvuruda talep
                    edilen hususu açık ve anlaşılır şekilde belirtmelidir. Başvuruya ilişkin
                    bilgi ve belgelerin başvuruya eklenmesi gerekmektedir.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="tr">
                    Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle birlikte,
                    başkası adına hareket ediliyor ise başvuruyu yapanın bu konuda özel olarak
                    yetkili olması ve bu yetkinin belgelendirilmesi (vekâletname)
                    gerekmektedir. Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve
                    başvuruya kimliği doğrulayıcı belgelerin eklenmesi gerekmektedir.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler değerlendirmeye
                    alınmayacaktır.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <b>
                    <span lang="tr">
                        5. Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar
                        Sürede Cevaplanır?
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">
                    Kişisel verilerinize ilişkin talepleriniz değerlendirilerek, Yapı
                    Merkezi’ne ulaştığı tarihten itibaren en kısa sürede ve en geç 30 gün
                    içinde cevaplandırılır. Talebinizin kabul edilmesi veya gerekçesi
                    açıklanarak reddedilmesi halinde cevabımız başvuruda belirttiğiniz adrese
                    posta veya elektronik posta yolu başta olmak üzere eğer mümkünse
                    talebinizin yapıldığı usulle size iletilir.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify", lineHeight: "150%" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
        </div>


    );
}