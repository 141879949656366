const content =
{
    err_ip_space: "Lütfen IP adresindeki boşlukları kaldırın",
    err_ip_format: "IP adres formatı x.x.x.x şeklinde olmalı (örn: 172.217.22.14)",
    err_ip_octet: "Oktetler 255'ten fazla olamaz",
    success_ip: "IP adresinizi kimseyle paylaşmayacağız..",
    err_date: "Lütfen geçerli bir tarih girin",
    err_min_date: "Minimum tarih 01/01/2022 olmalı",
    err_email: "Lütfen geçerli bir email adresi girin",
    success_email: "E-mail'inizi kimseyle paylaşmayacağız..",
    err_asset: "Asset en az 5 karakter uzunluğunda olmalı",
    err_cwe: "Değer en az 2 karakter uzunluğunda olmalı",
    err_name: "İsim en az 2 karakter uzunluğunda olmalı",
    err_title: "Başlık en az 5 karakter uzunluğunda olmalı",
    err_summary: "Özet en az 10 karakter uzunluğunda olmalı",
    err_reproduce: "Değer en az 10 karakter uzunluğunda olmalı",
    err_material: "Değer en az 10 karakter uzunluğunda olmalı",
    err_impact: "Değer en az 10 karakter uzunluğunda olmalı",
    err_remediation: "Değer en az 10 karakter uzunluğunda olmalı",
    err_validation: "• Bir veya birden çok doğrulama hatası var, lütfen değerlerinizi bildirmeden önce kontrol edin",
    label_title: "Başlık",
    label_asset: "Asset",
    label_cwe: "CWE ile zayıflık",
    label_summary: "Özet",
    label_payload: "Payload (Opsiyonel)",
    label_reproduce: "Yeniden Üretme Adımları",
    label_material: "Destekleyici Malzeme/Referanslar",
    label_impact: "Etki",
    label_remediation: "İyileştirme",
    label_name: "İsminiz",
    label_email: "Emailiniz",
    label_ip: "Public IP Adresiniz",
    label_score: "Skor",
    placeholder_asset: "örn: www.hepsiburada.com",
    placeholder_cwe: "örn: Cross-site Scripting(XSS) - Reflected (CWE-79)",
    placeholder_ip: "örn: 172.217.22.14",
    warning_calculate: "Formunuzu bildirmeden önce puanınızı hesaplamanız gerekir.",
    report_label: "BİLDİR",
    agreement_accept: "Gizlilik sözleşmesini ",
    agreement_name: "Okudum ve kabul ediyorum",
    err_network: "Ağ hatası oluştu",
    report_error_title: "Raporunuz gönderilirken bir sorun oluştu",
    report_error_text: "Lütfen raporunuzu daha sonra tekrar gönderin ",
    report_success_title: "Rapor başarıyla gönderildi!",
    report_success_text: "Yakında size e-posta ile ulaşacağız",
    clarification_paragraph: `Hepsiburada olarak, kişisel verilerinizi;
     sözleşme süreçlerinin yürütülmesi, iletişim faaliyetlerinin yürütülmesi, 
     bilgi güvenliği süreçlerinin yürütülmesi, iş süreçlerinin iyileştirilmesine 
     yönelik önerilerin alınması ve değerlendirilmesi amaçlarıyla sınırlı olarak işlemekteyiz. `,
    clarification_link: `Detaylı bilgi için "Hepsibounty Programı Aydınlatma Metni”ni inceleyiniz.`

};

export default content;