import React from "react";

export default function Agreement(props) {
    return (
        <div className="WordSection1">
            <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                <b>
                    <span lang="EN">
                        CLARIFICATION TEXT ON THE PROCESSING OF PERSONAL DATA WITHIN THE SCOPE
                        OF THE HEPSIBOUNTY PROGRAM{" "}
                    </span>
                </b>
            </p>
            <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                <b>
                    <span lang="tr">&nbsp;</span>
                </b>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN">
                        Data Controller: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; D-Market
                        Elektronik Hizmetler ve Ticaret A.Ş.
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ marginLeft: "108.0pt" }}>
                <span lang="tr">Kuştepe Mah. Mecidiyeköy Yolu Cad. </span>
                <span lang="EN">
                    Trump Towers Kule 2 Kat:2 No:12&nbsp; 34387 Şişli/İstanbul
                </span>
            </p>
            <p className="MsoNormal" style={{ marginLeft: "108.0pt" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">
                    As D-Market Elektronik Hizmetler ve Ticaret A.Ş.<b> ("Hepsiburada")</b>,
                    we have prepared this clarification text to inform you about the
                    processing, storage and transfer of your personal data specific to the
                    processes carried out within the scope of the Hepsibounty Program by the
                    Personal Data Protection Law No. 6698 (" <b>KVKK</b>") and the relevant
                    legislation.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="EN">1. Which Personal Data is Processed?</span>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">
                    Within the scope of the Hepsibounty Program participation process, your {" "}
                    <b>identity information</b> (name, surname),<i> contact information</i>{" "}
                    (e-mail address), <b>and transaction security information</b> (public IP
                    address) are collected and processed through electronic forms.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">
                    Your data is processed limited to conducting/supervising business
                    activities, conducting contract processes, conducting communication
                    activities, conducting information security processes, receiving and
                    evaluating suggestions for improving business processes, and providing
                    information to authorised persons, institutions and organisations.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <i>
                    <span lang="EN">
                        Your identity, contact and transaction security information will be
                        processed for the legal reason "Data processing is mandatory for the
                        legitimate interests of the data controller, provided that it does not
                        harm the fundamental rights and freedoms of the relevant person", which
                        is subparagraph (f) of paragraph 2 of Article 5 of the KVKK.
                    </span>
                </i>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="EN">
                        2. To whom and for what purposes will your data be transferred?
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">Your personal data,</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    Within the scope stipulated by the legal regulation, it may be transferred
                    to Authorized Public Institutions and Organizations to conduct activities
                    by the legislation and provide information to authorised persons,
                    institutions and{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">organisations.</span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <b>
                    <span lang="EN">
                        3. What Are Your Rights Regarding Your Personal Data as a Relevant
                        Person?
                    </span>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">
                    Regarding your personal data within the scope of KVKK and related
                    legislation, you have rights such as;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    Learning whether your personal data is being processed,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    Requesting information if your personal data has been processed,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    Learning the purpose of processing personal data and whether they are used
                    for their intended purpose,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    To know the third parties to whom your personal data is transferred
                    domestically or abroad,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    To request correction of your personal data in case of incomplete or
                    incorrect processing,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    To request the deletion or destruction of your personal data within the
                    framework of the conditions stipulated in the KVKK legislation,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    When you request the correction of incomplete or incorrect data and the
                    deletion or destruction of your personal data, request that this situation
                    be notified to third parties to whom we transfer your personal data,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    Objecting to the occurrence of a result to the prejudice of the person
                    themself by analysing the processed data exclusively through automated
                    systems and
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "10.0pt",
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                </span>
                <span lang="EN">
                    In case you suffer damage due to unlawful processing of personal data,
                    demand the{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">compensation of this damage. </span>
            </p>
            <h1 style={{ margin: "0cm", textAlign: "justify" }}>
                <b>
                    <span lang="EN" style={{ fontSize: "11.0pt", lineHeight: "115%" }}>
                        4. How Can You Exercise Your Rights as a Relevant Person?
                    </span>
                </b>
            </h1>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">
                    You can submit your applications and requests regarding your personal data
                    to HEPSİBURADA in the following ways:{" "}
                </span>
                <span lang="tr">
                    <a href="https://images.hepsiburada.net/assets/gif/hepsiburada/HB_KVKBasvuruFormu_20210111_2.pdf">
                        <b>
                            <span lang="EN" style={{ color: "#1155CC" }}>
                                https://images.hepsiburada.net/assets/gif/hepsiburada/HB_KVKBasvuruFormu_20210111_2.pdf
                            </span>
                        </b>
                    </a>
                </span>
                <span className="MsoHyperlink">
                    <b>
                        <span lang="EN" style={{ color: "#1155CC" }}>
                            .
                        </span>
                    </b>
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <i>
                    <span lang="EN">
                        If you wish, you can use the Relevant Person Application Form by
                        submitting it with a wet signature and a copy of the identity card to
                        Kuştepe Mah. Mecidiyeköy Yolu Cad. Trump Towers Kule 2 Kat :2 No:12
                        34387 Şişli/Istanbul{" "}
                    </span>
                </i>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">
                    By applying to our Company in person with a valid identity document,{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span lang="EN">By sending it to our registered e-mail address </span>
                <span lang="tr">
                    <a href="mailto:dmarket@hs02.kep.tr">
                        <span lang="EN" style={{ color: "#1155CC" }}>
                            dmarket@hs02.kep.tr
                        </span>
                    </a>
                </span>
                <span lang="EN">
                    {" "}
                    by using registered electronic mail (KEP) address and secure electronic
                    signature or mobile signature, or
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "10.0pt",
                    marginLeft: "36.0pt",
                    textAlign: "justify",
                    textIndent: "-18.0pt"
                }}
            >
                <span lang="tr">
                    ●
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                </span>
                <span lang="EN">or by sending it to </span>
                <span lang="tr">
                    <a href="mailto:kvk@hepsiburada.com">
                        <span lang="EN" style={{ color: "#1155CC" }}>
                            kvk@hepsiburada.com
                        </span>
                    </a>
                </span>
                <span lang="EN"> via your e-mail address previously </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">notified to our company and registered in our system.</span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "12.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify"
                }}
            >
                <span lang="EN">
                    Under the Communiqué on the Procedures and Principles of Application to
                    the Data Controller, the application of the Relevant Person must include
                    the name, surname, signature if the application is in writing, TR ID
                    number (if the applicant is a foreigner, their nationality, passport
                    number or ID number if any), residential or workplace address for
                    notification, e-mail address for notification, if any, telephone number
                    and fax number and information on the subject of the request.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginTop: "12.0pt",
                    marginRight: "0cm",
                    marginBottom: "12.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify"
                }}
            >
                <span lang="EN">
                    The Relevant Person must clearly and comprehensibly state the subject
                    requested in the application, which includes explanations regarding the
                    right to be exercised and requested to use the above-mentioned rights.
                    Information and documents related to the application must be attached to
                    the application.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{ marginBottom: "10.0pt", textAlign: "justify" }}
            >
                <span lang="EN">
                    Although the subject of the request must be related to the applicant's
                    identity, if they act on someone else’s behalf, the applicant must be
                    expressly authorised, and this authorisation must be documented (power of
                    attorney). In addition, the application must include identity and address
                    information and must be accompanied by identity verification documents.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">
                    Requests made by unauthorised third parties on behalf of someone else will
                    not be evaluated.
                </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <b>
                    <span lang="EN">
                        5. How long will your requests regarding the processing of your personal
                        data be answered?
                    </span>
                </b>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
                <span lang="EN">
                    Your requests regarding your personal data are evaluated and answered as
                    soon as possible and within 30 days at the latest from the date of receipt
                    by Yapı Merkezi. If your request is accepted or rejected with an
                    explanation of the reasons, our response will be sent to you by mail or
                    e-mail to the address you specified in your application, if possible, in
                    the same manner, your request was made.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify", lineHeight: "150%" }}>
                <span lang="tr">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="tr">&nbsp;</span>
            </p>
        </div>
    );
}