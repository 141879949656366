const content =
{
    ranking_desc: `All reported vulnerabilities are checked for validity, ranked, and then reviewed by the
        Hepsiburada AppSec team.
        Hepsiburada has established a Vulnerability Ranking Matrix based on NIST's Common Vulnerability 
        Scoring System V3. The Vulnerability
        Ranking Matrix is defined below. The final ranking for a vulnerability is the sole discretion of Hepsiburada AppSec Team`,
    ranking_header: "Ranking Vulnerabilities",
    cvss_calc: "CVSS Calculator",
    critical: "Critical",
    high: "High",
    moderate: "Moderate",
    low: "Low",
    homepage:"Homepage",
    acceptable: "Acceptable",
    critical_desc: `Vulnerabilities that cause a privilege escalation on the platform from unprivileged to
        admin, allows remote code execution, financial theft, large scale access to PII, etc..
        At the discretion of Appsec Team, vulnerabilities that demonstrate a critical,
        widespread risk to information security may be eligible to receive a reward greater than
        the standard bounty.
        Example: Vulnerabilities that result in unrestricted Remote Code Execution such as
        Vertical Authentication bypass, SSRF, XXE, SQL Injection, User authentication bypass.`,
    high_desc: `Vulnerabilities that affect the security of the platform including the processes it
        supports.
        Example: Lateral authentication bypass, Stored XSS, some CSRF depending on impact.`,
    moderate_desc: `Vulnerabilities that affect multiple users, and require little or no user interaction to
        trigger.
        Example: Some reflective XSS, Some direct object reference, URL Redirect, some CSRF
        depending on impact.`,
    low_desc: `Issues that affect singular users and require interaction or significant
        prerequisites (MITM) to trigger.
        Example: Common flaws, Detailed debug information.`,
    acceptable_desc: `Non-exploitable weaknesses and “won't fix” vulnerabilities.Best practices,
        mitigations, issues that are by design or acceptable business risk to the
        customer such as use of CAPTCHAS.`,
    domain_desc: `The following domains are included in this program.`,
    domain_url: `www.hepsiburada.com (e-commerce pages)`,
    exclusion_header: `Scope Exclusions`,
    exclusion_desc: `The following categories of reports are considered out of scope for our program and
    Hepsiburada will NOT provide any reward pay out:`,
    exclusion_note1: `Any vulnerability with a CVSS 3 score lower than 4.0, unless it
    can be combined with other vulnerabilities to achieve a higher score.`,
    exclusion_note2: `Brute force, DoS, DDoS, phishing, text injection, or social
    engineering attacks. Wikis, Tracs, forums, etc are intended to allow users to
    edit them.`,
    exclusion_note3: `Availability of XML-RPC file without PoC demonstrating a
    significant security impact. As noted above, this excludes DDoS and brute force
    attacks.`,
    exclusion_note4: `Security vulnerabilities in WordPress plugins.`,
    exclusion_note5: `Self-XSS`,
    exclusion_note8: `Mixed content warnings for passive assets like images and videos.`,
    exclusion_note9: `Clickjacking with minimal security implications`,
    exclusion_note10: `Non critical issues that affect only outdated browsers.`,
    exclusion_note11: `Cross-site Request Forgery (CSRF) with minimal security
    implications (Login/logout/unauthenticated).`,
    exclusion_note14: `Missing cookie flags on non-sensitive cookies.`,
    exclusion_note15: `Reports of non-exploitable vulnerabilities and violation of
    “best practices” (e.g. Lack of HTTP security headers (CSP, X-XSS, etc.)`,
    exclusion_note16: `Lack of secure/HTTP-only flags on non-session cookies.`,
    exclusion_note17: `Server error messages that do not contain internal, confidential
    or restricted data or avenues to obtain it.`,
    exclusion_note18: `DNS record configuration (SPF, DKIM, DMARC, CAA, DNSSEC, etc).`,
    exclusion_note19: `Theoretical vulnerabilities where you can't demonstrate a
    significant security impact with a PoC.`,
    exclusion_note20: `Some forms do not have rate limiting / brute-force protections.
    We will consider this out of scope for the program.`,
    exclusion_note21: `Known issues that have been previously reported.`,
    change_header: `Changes To The Program`,
    change_desc: `We may update or suspend this program at any time without any prior notice. We
    encourage you to periodically review this page for the latest information on this
    program. Any submitted reports will be processed using the program terms in effect
    at the time our AppSec Team reviews the report.`,
    change_date: `Effective Date of this Privacy Policy: 01/03/2022`,
    cvss_desc: `You should calculate the vulnerability's severity here, before reporting the vulnerability. 
    Every option is self explanatory, just hover over the info icon to see the explanation. 
    To see the vulnerability's severity score, you need to complete all of the calculator's steps first.`,
    report_header: `Report Vulnerability`,
    report_desc: "Please report the vulnerability here.",
    about_summary_header: "What Is Bugbounty Program?",
    about_summary_desc: `A bug bounty program is a deal offered by many websites, 
    organizations and software developers by which individuals can receive 
    recognition and compensation for reporting bugs, especially those pertaining
     to security exploits and vulnerabilities. These programs allow the developers
      to discover and resolve bugs before the general public is aware of them, 
      preventing incidents of widespread abuse. Bug bounty programs have been 
      implemented by a large number of organizations, including Mozilla, Facebook,
       Yahoo!, Google, Reddit, Square, Microsoft, and the Internet bug bounty.`,
    summary_button: "Report now",
    page_notfound: "Sorry, the page you are looking for could not be found.",
    scope: "Scope",
    about: "About",
    report: "Report",
    ranking: "Ranking",
    summary_caption: "Looking For Bug Hunters",
    summary_welcome: "Welcome to Hepsiburada CyberBounty",
    summary_desc: "We're Hepsiburada Application Security Team that keeps Hepsiburada safe and secure. We are awaiting for your contributions.",
    summary_report_button: "Report A Security Vulnerability",
    steps_caption: "Steps To Report",
    steps_desc: "The three steps to report security vulnerabilities",
    step_one: "Step 1",
    step_one_header: "Ranking",
    step_one_desc: "See our ranking classification",
    step_one_button: "See Ranking",
    step_two: "Step 2",
    step_two_header: "Scope",
    step_two_desc: "Check out the scopes",
    step_two_button: "Check Scopes",
    step_three: "Step 3",
    step_three_header: "Report",
    step_three_desc: "Report the vulnerability",
    step_three_button: "Report",
    scope_header: "Scope",
    scope_desc: `Subdomains used in Hepsiburada e-commerce system are within our scope.
    Vulnerabilities that outside of these subdomains will not be considered within the scope of cyberbounty.
    The subdomains included in the scope of e-commerce are listed below: `,

};

export default content;