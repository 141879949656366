import React from "react";
import { useTranslation } from 'react-i18next';
import "./Score.css";

export default function Score(props) {
    const { t } = useTranslation();
    const getRank = (score) => {
        if (score >= 9)
            return "critical";
        else if (score >= 7)
            return "high";
        else if (score >= 5)
            return "moderate"
        else
            return "low";
    };

    return (
        <div className="score--container">
            <div className={`score--text--${getRank(props.score)}`}>
                <div className="score--number">{props.score}</div>
                <div className="score--header">{t(`score_content.${getRank(props.score)}.header`)}</div>
            </div>
            <div className="score--description">{t(`score_content.${getRank(props.score)}.description`)}</div>
        </div>
    );
}