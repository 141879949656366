const content =
{
    err_ip_space: "Please remove spaces in the IP address",
    err_ip_format: "The IP address format must be x.x.x.x (exp: 172.217.22.14)",
    err_ip_octet: "The octets cannot be more than 255",
    success_ip: "We'll never share your IP address with anyone else.",
    err_date: "Please enter a valid date",
    err_min_date: "The minimum date must be 01/01/2022",
    err_email: "Please enter a valid email address",
    success_email: "We'll never share your email with anyone else.",
    err_asset: "Asset must be at least 5 characters long",
    err_cwe: "Value must be at least 2 characters long",
    err_name: "Name must be at least 2 characters long",
    err_title: "Title must be at least 5 characters long",
    err_summary: "Summary must be at least 10 characters long",
    err_reproduce: "Value must be at least 10 characters long",
    err_material: "Value must be at least 10 characters long",
    err_impact: "Value must be at least 10 characters long",
    err_remediation: "Value must be at least 10 characters long",
    err_validation: "• There are one or more validation errors, please check your values before reporting",
    label_title: "Title",
    label_asset: "Asset",
    label_cwe: "Weakness with CWE",
    label_summary: "Summary",
    label_payload: "Payload (Optional)",
    label_reproduce: "Steps To Reproduce",
    label_material: "Supporting Material/References",
    label_impact: "Impact",
    label_remediation: "Remediation",
    label_name: "Your Name",
    label_email: "Your Email",
    label_ip: "Your Public IP Address",
    label_score: "Score",
    placeholder_asset: "exp: www.hepsiburada.com",
    placeholder_cwe: "exp: Cross-site Scripting(XSS) - Reflected (CWE-79)",
    placeholder_ip: "exp: 172.217.22.14",
    warning_calculate: "You must calculate your score before reporting your form",
    report_label: "Report",
    agreement_accept: "I have read and agreed to the ",
    agreement_name: "Confidentiality Agreement",
    err_network: "Network error has been thrown",
    report_error_title: "There was a problem sending your report",
    report_error_text: "Please send your report again later ",
    report_success_title: "Report successfully sent!",
    report_success_text: "We'll reach out to you by email soon",
    clarification_paragraph: `As Hepsiburada, we process your personal data 
    limited to executing contract processes, conducting communication activities, 
    conducting information security processes, and receiving and evaluating suggestions 
    for improving business processes. `,
    clarification_link:`Please refer to the "Clarification Text of the Hepsibounty Program" for detailed information.`

};

export default content;