import React from "react";

export default function Confidentiality(props) {
    return (
        <div className="WordSection1">
            <p
                className="MsoTitle"
                style={{ marginLeft: "0cm", textIndent: "-.1pt", lineHeight: "115%" }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    CONFIDENTIALITY AGREEMENT
                </span>
            </p>
            <p
                className="MsoTitle"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif',
                        fontWeight: "normal"
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    D-Market Elektronik Hizmetler ve Ticaret A.Ş. located at Kuştepe Mahallesi
                    Mecidiyeköy Yolu Caddesi No:12 Trump Towers Kule:2 Kat:2 Şişli-İstanbul ("
                    <b>Disclosing Party</b>") on one side and
                    ................................... located at ………………........ ("
                    <b>Receiving Party</b>") on the other side have signed this
                    Confidentiality Agreement ("Agreement") upon complete agreement on the
                    following terms.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        The Disclosing Party and the Receiving Party will
                    </span>
                    <span lang="EN"> </span>
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        be referred to as the “Parties” in this Agreement.
                    </span>
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    As the information to be disclosed under the Parties'{" "}
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        Hepsibounty{" "}
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Program (the "
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        Project
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    ") is considered sensitive and confidential due to the nature of this
                    Project, the Parties hereby agree as follows:
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Except as otherwise provided in this Agreement,{" "}
                    <b>"Confidential Information</b>" shall mean any information and
                    documents, including but not limited to the following:{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    "
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        Personal data as defined in Law No. 6698 on the Protection of Personal
                        Data
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    ('Law' or 'Law No. 6698'), customer information, sales, profit and loss,
                    costs, payment information, account information, any financial information
                    including bank information, services, products, product development
                    information, personnel information, salary policies and levels, ideas,
                    inventions, any information and documents, whether written or oral or in
                    any other form, including but not limited to know-how, trademarks, logos,
                    patents, software, source codes, intellectual and industrial property
                    rights, trade secrets, technical processes, formulas, plans, designs,
                    licenses and permits, drawings, layouts, models, projections, reports,
                    business plans of the Disclosing Party"
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    {" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif',
                        color: "#222222",
                        background: "white"
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Disclosing Party shall provide Confidential Information to the
                    Receiving Party solely for the evaluation and implementation of the
                    Project and for no other purpose. This Agreement does not imply that the
                    Parties will mutually agree, cooperate and sign an agreement as a result
                    of the evaluation of the Project.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Concerning the disclosure of Confidential Information, the Receiving Party
                    undertakes to keep all Confidential Information strictly confidential and,
                    except as otherwise provided in this Agreement, shall not buy, sell or
                    trade, store in a database, publish or otherwise disseminate such
                    information, including photocopies or other reproductions thereof, to
                    third parties without the prior written consent of the Disclosing Party
                    and shall not reverse engineer the Confidential Information.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party shall not use or retain any part of the Confidential
                    Information for any purpose other than the limited purposes outlined in
                    this Agreement unless it has obtained the Disclosing Party's prior express
                    written consent.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party shall take all measures to prevent disclosure of such
                    Confidential Information to unauthorised third parties. The Receiving
                    Party agrees, represents and undertakes that it shall be exclusively
                    liable to the Disclosing Party for any damages that may arise from
                    unauthorised disclosures by third parties to whom it transfers
                    Confidential Information or who obtain Confidential Information from it in
                    any way.
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    {" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        6.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party shall limit access to Confidential Information to its
                    employees,{" "}
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        if any
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    , retained by the Receiving Party who require such Confidential
                    Information for the expressly stated limited purposes of this Agreement
                    and shall require each such person to accept and comply with the terms of
                    this Agreement. The Receiving Party{" "}
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    acknowledges, agrees and undertakes that it is solely responsible for any
                    actions of its employees to whom it discloses Confidential Information.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        7.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Where project-related activities require remote access to the Disclosing
                    Party's environments, and only with the Disclosing Party's consent, the
                    Receiving Party may be granted restricted access authorisations consistent
                    with the objectives and realisation of the Project. These authorisations
                    will not be shared with any third party in such a case. The Receiving
                    Party{" "}
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        accepts, declares
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    and undertakes that it will access the systems to be displayed by the
                    Disclosing Party only by the policies and procedures on access, risk
                    management, information security, confidentiality, customer information
                    sharing, audit trails, information systems continuity plan to be
                    communicated to the Receiving Party by the Disclosing Party, that the
                    password, user name and similar information to be defined to the Receiving
                    Party for access to the system will not be shared with unauthorised
                    persons, that this information will not be used for any purpose other than
                    the performance of the projects, and that the Receiving Party is
                    exclusively responsible for any access and transaction made using this
                    information.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textIndent: "-.1pt",
                    lineHeight: "115%",
                    border: "none"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif',
                        color: "black"
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        8.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party acknowledges and agrees that it has been fully
                    informed of and will comply with any applicable legislation and the
                    Disclosing Party's internal regulations and instructions of which it
                    should be aware in the delivery of the Project.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        9.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party shall, upon request or completion of the business
                    purpose, whichever occurs first, promptly return the Disclosing Party the
                    original documents and any electronic documents about the Confidential
                    Information and irrevocably destroy all copies thereof. The Receiving
                    Party warrants that all copies and reproductions of Confidential
                    Information in its possession or in possession of any other party to whom
                    the information is disclosed will be destroyed.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        10.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party may disclose Confidential Information without the
                    prior written consent of the Disclosing Party only in the following
                    circumstances. In such cases, the Receiving Party shall give the
                    Disclosing Party reasonable advance notice so that the Disclosing Party
                    can take the necessary measures to protect its interests.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Arial",sans-serif'
                        }}
                    >
                        10.1
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The information has the Receiving Party before disclosure and has not been
                    received, directly or indirectly, from the Disclosing Party, without any
                    legal limitation on its disclosure and use,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Arial",sans-serif'
                        }}
                    >
                        10.2
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The information is or becomes publicly available in writing or
                    electronically without any act or omission on the part of the Receiving
                    Party or its employees or third parties, such as subcontractors of the
                    Receiving Party,
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Arial",sans-serif'
                        }}
                    >
                        10.3
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The disclosure of the information is requested by a competent court or an
                    official body under applicable law.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        11.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party agrees that the Confidential Information is
                    proprietary property, i.e., the property of the Disclosing Party, and
                    shall be held in strict confidence and shall not be sold, exchanged,
                    traded, published or otherwise disclosed to any person, except as
                    otherwise permitted under this Agreement. In addition, the Receiving Party
                    undertakes to exercise at least the same degree of control, protection and
                    safeguarding concerning the use and retention of such Confidential
                    Information as it would exercise concerning the use and retention of data
                    and information in its possession.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        12.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    If it is necessary to share personal data with the Receiving Party for
                    purposes related to the performance of the Project under this Agreement,
                    the relevant personal data will be processed by the Receiving Party
                    exclusively for this Agreement by the relevant legislation, especially{" "}
                </span>
                <span lang="TR">
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        Law No. 6698
                    </span>
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    , and the decisions of the Personal Data Protection Board.
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    {" "}
                    It is strictly forbidden to process personal data beyond the scope above.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The transfer of personal data to a third party, either domestically or
                    abroad, for any reason whatsoever, including for the reason that the
                    Receiving Party is required to use third-party services as part of the
                    provision of services to the Disclosing Party, is prohibited unless
                    otherwise agreed in writing between the Parties.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Upon termination of the Agreement, the Receiving Party shall deliver to
                    the Disclosing Party, against signature, all media and media on which
                    personal data are stored, and after that, delete and destroy the records
                    in its possession.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party is obliged to take all necessary technical and
                    administrative measures to ensure the level of security appropriate to the
                    nature of the personal data to prevent unauthorised access to personal
                    data by both itself and third parties and to prevent the use of personal
                    data for purposes other than the transfer of personal data to it.
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party accepts and declares that the measures to be taken
                    within this scope shall, in any case, not be less than the measures taken
                    for the security of personal data stored by the applicable legislation,
                    the Personal Data Security Guide published by the Personal Data Protection
                    Authority or a prudent merchant operating in similar fields.
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    {" "}
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    If the Disclosing Party shares "special categories of personal data"
                    within the scope of the Agreement, such data shall be protected by the
                    Receiving Party subject to additional security measures and authorisations
                    by the Decision of the Personal Data Protection Board dated 31/01/2018 and
                    numbered 2018/10 on "Adequate Measures to be Taken by Data Controllers in
                    the Processing of Special Categories of Personal Data".
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                ></span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    In cases where personal data is transferred to a third party by the
                    Receiving Party, provided that the Disclosing Party is permitted under
                    this Agreement, the Receiving Party shall be responsible for ensuring that
                    the data transfer is secure. In the event of any unauthorised access to
                    personal data or the event that personal data becomes accessible to third
                    parties in any way contrary to this Agreement, the Receiving Party shall
                    immediately (within the same day) notify the Disclosing Party of this
                    situation and shall provide any information, documents and support
                    requested by the Disclosing Party without delay to minimise the damage
                    arising from this situation.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party is obliged to inform its subcontractor and its
                    subcontractor's personnel in writing on the matters stipulated under this
                    Agreement regarding the processing of personal data. The Receiving Party
                    shall be jointly and severally liable for any damages that may arise if
                    the personnel above violate the regulations above.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party is obliged to comply with any instructions of the
                    Disclosing Party regarding the personal data transmitted to it by the
                    Disclosing Party under this Agreement and to fulfil the requests of the
                    data subject immediately. Requests that cannot be fulfilled on the same
                    day for a justified and reasonable reason will be fulfilled on the
                    following business day after the reason is notified in writing.
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                ></span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    If the data subject requests directly from the Receiving Party in any way,
                    the Disclosing Party will be notified in writing immediately (in any case,
                    the next business day) regarding such request, and action will be taken by
                    the Disclosing Party's requests and instructions.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party accepts and declares that it is subject to the
                    supervision of the Disclosing Party and the Personal Data Protection Board
                    regarding personal data processing under the Agreement. The Disclosing
                    Party may exercise such audit right in person or by a third party or
                    request the Receiving Party to perform such audit. If, as a result of such
                    audits, it is determined that the Receiving Party has violated its
                    obligations regarding personal data, the relevant audit costs will be
                    borne by the Receiving Party, and the Disclosing Party reserves the right
                    to offset these amounts from the payments to be made to the Receiving
                    Party.
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Disclosing Party may terminate this Agreement immediately if the
                    Receiving Party does not allow or fails to perform the requested audit.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Receiving Party is obliged to act by all regulations, procedures and
                    principles in force regarding the protection of personal data, in
                    particular, the Personal Data Protection Law No. 6698 and the decisions of
                    the Personal Data Protection Board. If a change is required in the
                    Receiving Party's processes due to any changes or updates that may occur
                    under regulations above, the Receiving Party is obliged to complete such
                    change at the latest before the relevant new/updated regulation enters
                    into force. If such an arrangement requires an amendment to this
                    Agreement, the Parties shall amend the Agreement accordingly. The
                    Agreement provision that requires amendment shall be applied by the
                    current legislation as of the effective date, even if the Parties have
                    taken no action in this regard.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span lang="TR">&nbsp;&nbsp;&nbsp;&nbsp; </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    If the Disclosing Party suffers any damages, is subject to any legal,
                    administrative or criminal sanction, or is required to indemnify the
                    Receiving Party for any damages under this Agreement, including any breach
                    by the Disclosing Party of the provisions of this Agreement relating to
                    personal data, such amounts shall be recourse to the Receiving Party and
                    shall be paid to the Disclosing Party upon first written demand, together
                    with all accessories to it. The Disclosing Party reserves the right to set
                    off the amounts referred to in this clause against payments made to the
                    Receiving Party.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        13.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    The Disclosing Party discloses Confidential Information in good faith but
                    makes no representations or warranties, either express or implicit, as to
                    the nature, accuracy or completeness of the Confidential Information
                    disclosed below. The Disclosing Party, its affiliates, officers,
                    directors/managers and employees shall have no liability whatsoever for
                    the Receiving Party's use of, and reliance on the Confidential Information
                    and the Receiving Party hereby releases the Disclosing Party from all
                    responsibility and liability for errors and omissions in the Confidential
                    Information, provided that such errors and omissions are not due to fraud
                    or willful misconduct.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        14.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Without prejudice to any other rights or remedies of indemnification or
                    compensation that the Disclosing Party may have, the Receiving Party
                    acknowledges that damages may not be an adequate remedy for its (or any
                    other person to whom the Receiving Party has disclosed Confidential
                    Information) breach of the terms of this Agreement and that the Disclosing
                    Party shall be entitled to injunctive, declaratory or other equitable
                    remedies for any actual or threatened breach of this Agreement.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textIndent: "-.1pt",
                    lineHeight: "115%",
                    border: "none"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif',
                        color: "black"
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    If the Receiving Party acts contrary to the confidentiality obligations
                    undertaken in this Agreement, including the provisions regarding personal
                    data and as a result of the said works and behaviours, constitute an
                    information crime within the scope of the Turkish Penal Code No. 5237,{" "}
                </span>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    the Disclosing Party agrees and undertakes to immediately pay to the
                    Disclosing Party in cash and lump sum all kinds of pecuniary,
                    non-pecuniary, direct and indirect damages, including but not limited to
                    loss of profit and reputation of the Disclosing Party, and in addition to
                    this, a penal clause in the amount of twice the amount of the damage.
                </span>
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    {" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        15.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Except as expressly outlined in this Agreement, no privileges, powers or
                    rights are granted to the Receiving Party, either impliedly or in any
                    other way.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        16.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    Neither this Agreement nor any rights hereunder may be assigned in whole
                    or part.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        17.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    This Agreement shall enter into force as of the date of signature, and the
                    confidentiality obligations of the parties shall continue indefinitely.
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="TR"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        18.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    This Agreement is subject to Turkish Law, and Istanbul Central Courts and
                    Execution Offices are authorised for disputes between the parties.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="EN"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    This Agreement was signed in duplicate on .../.../2022 and entered into
                    force.{" "}
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <u>
                        <span
                            lang="EN"
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Calibri",sans-serif'
                            }}
                        >
                            Disclosing Party
                        </span>
                    </u>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        D-MARKET ELEKTRONİK HİZMETLER VE TİC. A.Ş.
                    </span>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <span
                    lang="TR"
                    style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <u>
                        <span
                            lang="EN"
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Calibri",sans-serif'
                            }}
                        >
                            Receiving Party{" "}
                        </span>
                    </u>
                </b>
            </p>
            <p
                className="MsoNormal"
                style={{
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-.1pt",
                    lineHeight: "115%"
                }}
            >
                <b>
                    <span
                        lang="EN"
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Calibri",sans-serif'
                        }}
                    >
                        .......................&nbsp;
                    </span>
                </b>
            </p>
        </div>
    );
}