const validationFunctions = {
    ipAddress: value => {
        if (value.match(/\s/g)) {
            return { result: false, message: "err_ip_space" };
        }
        const numberParts = value.split(".");
        if (value.length >= 7 && value.length <= 15 && numberParts.length === 4) {
            for (let x of numberParts) {
                if (!/^\d+$/.test(x)) {
                    return { result: false, message: "err_ip_format" };

                }
                if (parseInt(x) > 255) {
                    return { result: false, message: "err_ip_octet" };
                }
            }
            return { result: true, message: "success_ip" };
        }
        return { result: false, message: "err_ip_format" };
    },
    vulnerabilityDate: value => {
        const dateValue = Date.parse(value);
        const minDate = new Date("01/01/2022")
        if (isNaN(dateValue)) {
            return { result: false, message: "err_date" };
        }
        if (dateValue < minDate) {
            return { result: false, message: "err_min_date" };
        }
        return { result: true, message: "" };
    },
    userEmail: value => {
        if (String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            return { result: true, message: "success_email" };
        }
        return { result: false, message: "err_email" };
    },
    asset: value => {
        if (value.length >= 5) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_asset" };
    },
    cwe: value => {
        if (value.length >= 2) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_cwe" };
    },
    userName: value => {
        if (value.length >= 2) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_name" };
    },
    title: value => {
        if (value.length >= 5) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_title" };
    },
    summary: value => {
        if (value.length >= 10) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_summary" };
    },
    payload: value => {
        return { result: true, message: "" };
    },
    reproduce: value => {
        if (value.length >= 10) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_reproduce" };
    },
    material: value => {
        if (value.length >= 10) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_material" };
    },
    impact: value => {
        if (value.length >= 10) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_impact" };
    },
    remediation: value => {
        if (value.length >= 10) {
            return { result: true, message: "" };
        }
        return { result: false, message: "err_remediation" };
    }
}

export default validationFunctions;