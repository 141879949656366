import React from "react";

export default function Confidentiality(props) {
    return (
        <div className="WordSection1">
            <p className="MsoTitle" style={{ "marginLeft": "0cm", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>GİZLİLİK&nbsp;
                SÖZLEŞMESİ</span></p>
            <p className="MsoTitle" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif", "fontWeight": "normal" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Bir tarafta Kuştepe Mahallesi Mecidiyeköy
                Yolu Caddesi No:12 Trump Towers Kule:2 Kat:2 Şişli-İstanbul adresinde mukim
                D-Market Elektronik Hizmetler ve Ticaret A.Ş. (“<b>İfşa Eden Taraf</b>”)&nbsp; ile
                diğer </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>tarafta .......... adresinde mukim ....... (“<b>Kabul
                    Eden Taraf</b>”)&nbsp; aşağıdaki şartlarda tam bir mutabakat sağlayarak işbu
                    Gizlilik Sözleşmesi’ni (“Sözleşme”) imza altına almışlardır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İfşa Eden Taraf ve Kabul Eden Taraf işbu
                Sözleşme’de birlikte “Taraflar” olarak anılacaktır.</span></span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Taraf</span><span lang="TR">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>ların</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                Hepsibounty Programı (“Proje”) kapsamında </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>ifşa
                    edilecek bilgiler iş</span><span lang="TR">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>bu
                        Proje’nin içeriği sebebiyle hassas ve gizli olarak kabul edildiğinden
                        Taraflar,&nbsp; aşağıda belirtildiği şekilde bir anlaşmaya varmışlardır:</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>1.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme’de aksi belirtilmedikçe “<b>Gizli
                Bilgiler</b>”, aşağıda belirtilenler dahil, ancak bunlarla sınırlı kalmamak
                    kaydıyla her türlü bilgi ve belge anlamına gelecektir: </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>“</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>6698 sayılı Kişisel
                Verilerin Korunması Kanunu (<b>‘’Kanun</b>” veya “</span><b><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>6698
                    sayılı Kanun</span></b><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>”)</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>’da
                        tanımlandığı şekilde kişisel veriler, müşteri bilgileri, satış, kar-zarar,
                        maliyetler, ödeme bilgileri, hesap bilgileri, banka bilgileri de dahil her
                        türlü finansal bilgiler, hizmetler, ürünler, ürün geliştirme bilgileri,
                        personel bilgileri, maaş politika ve düzeyleri, fikirler, buluşlar,
                        know-howlar, markalar, logolar, patentler, yazılımlar, kaynak kodları, fikri ve
                        sınai mülkiyet hakları, ticari sırlar, teknik prosesler, formüller, planlar,
                        tasarılar, lisans ve izinler, çizimler, tertipler, modeller, projeksiyonlar,
                        raporlar, İfşa Eden Taraf’ın iş planları da dahil ve fakat bunlarla sınırlı
                        olmaksızın yazılı veya sözlü ya da bir başka formdaki her türlü bilgi ve/veya
                        belge” </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif", "color": "#222222", "background": "white" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>2.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Gizli Bilgiler, İfşa Eden Taraf’ça, başkaca
                bir amaç için değil, sadece Proje’nin değerlendirilmesi ve uygulanması amacıyla
                Kabul Eden Taraf’a temin edilecektir.&nbsp; İşbu Sözleşme, Tarafların karşılıklı
                olarak Proje’nin değerlendirilmesi sonucu anlaşacağı, işbirliği yapacağı ve bir
                anlaşma imzalayacağı anlamına gelmemektedir. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>3.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Gizli Bilgiler’in ifşa edilmesi ile ilgili
                olarak Kabul Eden Taraf, tüm Gizli Bilgiler’i son derece gizli tutmayı taahhüt
                eder ve işbu Sözleşme’de aksi belirtilmedikçe, bu bilgileri, fotokopi ya da
                başka şekildeki çoğaltmaları da dahil olmak üzere, İfşa Eden Taraf’ın yazılı
                iznini önceden almaksızın üçüncü kişilere alım, satım ya da ticarete konu
                etmeyecek, veri tabanında saklamayacak, yayınlamayacak ya da başka bir şekilde
                yaymayacak ve Gizli Bilgiler’e tersine mühendislik uygulanmayacaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>4.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, İfşa Eden Taraf’ın önceden
                açıkça ve yazılı olarak onayını almadığı sürece, Gizli Bilgiler’in tamamını
                veya herhangi bir kısmını işbu Sözleşmede belirtilen sınırlı amaçlar dışında
                herhangi bir amaç için kullanmayacak ve saklamayacaktır. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>5.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, bu Gizli Bilgiler’in
                yetkisiz üçüncü taraflara ifşasını önlemek amacıyla her tür tedbiri alacaktır.
                Kabul Eden Taraf, Gizli Bilgiler’i aktardığı veya Gizli Bilgiler’i kendisinden
                herhangi bir şekilde edinen üçüncü kişiler tarafından gerçekleştirilen yetkisiz
                ifşalardan meydana gelebilecek zararlardan dolayı İfşa Eden Taraf’a karşı
                münhasıran sorumlu olacağını </span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>kabul, beyan ve </span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>taahhüt
                    eder. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>6.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, Kabul Eden Taraf’ça tutulan
                ve işbu Sözleşme’nin açıkça belirtilmiş sınırlı amaçları için bu Gizli
                Bilgiler’e ihtiyaç duyan </span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>var ise </span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>çalışanlarının
                    Gizli Bilgiler’e erişimine sınırlama getirecektir ve bu kişilerin her birinin
                    işbu Sözleşme koşullarını kabul etmelerini ve bu koşullara uymalarını zorunlu
                    tutacaktır. Kabul Eden Taraf</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>,</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                    Gizli Bilgiler’i ifşa ettiği çalışanlarının her türlü hareketinden münhasıran
                    sorumlu olduğunu kabul, beyan ve taahhüt eder.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>7.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Proje ile ilgili faaliyetlerin İfşa Eden
                Taraf’ın ortamlarına uzaktan erişimini gerektirdiği hallerde ve ancak İfşa eden
                Taraf’ın uygun bulması durumunda Kabul Eden Taraf’a, Proje’nin amaçları ve
                gerçekleşebilmesi ile uyumlu ve kısıtlı erişim yetkileri tanımlanabilir. Böyle
                bir durumda bu yetkiler, hiçbir üçüncü taraf ile paylaşılmayacaktır. Kabul Eden
                Taraf, İfşa Eden Taraf’ça gösterilecek sistemlere ancak İfşa Eden Taraf’ça
                Kabul Eden Taraf’a iletilecek erişim, risk yönetimi, bilgi güvenliği, gizlilik,
                müşteri bilgileri paylaşımı, denetim izleri, bilgi sistemleri süreklilik planı
                konularındaki politika ve prosedürlerine uygun olarak erişeceğini, sisteme
                erişim için Kabul Eden Taraf’a tanımlanacak şifre, kullanıcı adı ve benzeri
                bilgilerin yetkisiz kişilerle paylaşılmayacağını, bu bilgilerin Projelerin
                ifası dışında herhangi bir amaç için kullanılmayacağını, bu bilgiler
                kullanılarak yapılan her türlü erişim ve işlemden Kabul Eden Taraf’ın
                münhasıran sorumlu olduğunu kabul,</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}> beyan ve taahhüt</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                    eder.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%", "border": "none" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif", "color": "black" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>8.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, Proje’nin sunulmasında
                bilmesi gereken her türlü uygulanabilir mevzuat ile İfşa Eden Taraf’ın iç
                yönetmelikleri ve/veya talimatları konusunda eksiksiz olarak
                bilgilendirildiğini ve bunlara uygun hareket edeceğini kabul ve beyan eder. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>9.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, talep üzerine ya da ticari
                amacın tamamlanmasıyla&nbsp; –hangisi ilk olarak meydana gelirse - Gizli Bilgiler’e
                ait orijinal belgeleri, her türlü elektronik dokümanları İfşa Eden Taraf’a
                derhal iade edecektir ve tüm kopyalarını geri dönüşü olmayacak şekilde imha
                edecektir.&nbsp; Kabul Eden Taraf, kendi ya da bilgilerin ifşa edildiği herhangi
                başka bir tarafın tasarrufunda olan Gizli Bilgiler’e ait tüm suret ve
                çoğaltmaların imha edileceğini garanti eder. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>10.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, İfşa Eden Taraf’ın yazılı
                iznini önceden almadan, Gizli Bilgiler’i, sadece aşağıda belirtilen durumlarda
                ifşa edebilir. Bu durumlarda Kabul Eden Taraf, İfşa Eden Taraf’ın menfaatlerini
                koruyabilmesi amacıyla gerekli tedbirlerin alınabilmesi için İfşa Eden Taraf’ı
                makul süre önceden bilgilendirir.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Arial\",sans-serif" }}>10.1<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Bilgilerin ifşasına ve kullanımına ilişkin
                yasal herhangi bir sınırlama olmaksızın, ifşadan önce Kabul Eden Taraf’ın
                tasarrufunda bulunması ve İfşa Eden Taraf’tan doğrudan ya da dolaylı olarak
                alınmamış olması durumu,</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Arial\",sans-serif" }}>10.2<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Bilgilerin Kabul Eden Taraf’ça veya
                çalışanları tarafından ya da Kabul Eden Taraf’ın alt yüklenicileri vb. üçüncü
                taraflarca gerçekleştirilen bir davranış ya da ihmal olmaksızın yazılı olarak
                ya da elektronik ortamda kamuya açık olması ya da açık hale gelmesi durumu,</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Arial\",sans-serif" }}>10.3<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Bilgilerin ifşasının, yetkili bir mahkeme ya
                da uygulanabilir hukuk tahtında resmi bir kurum tarafından talep edilmesi
                durumu.&nbsp; </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>11.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, Gizli Bilgiler’in özel
                mülk, yani İfşa Eden Taraf’a ait mülk olduğunu ve İşbu Sözleşme uyarınca aksine
                izin verilmediği sürece son derece gizli tutulacağını, herhangi bir kişiye
                satılmayacağını, takas veya ticari işleme konu edilmeyeceğini,
                yayınlamayacağını ya da herhangi başka bir şekilde ifşa edilmeyeceğini kabul
                eder. Buna ek olarak, Kabul Eden Taraf, bu Gizli Bilgiler’in kullanımı ve
                muhafazası ile ilgili olarak, en az, kendi mülkiyetinde bulunan veri ve
                bilgilerin kullanımı ve muhafazası ile ilgili olarak uygulayacağı kontrol,
                koruma ve muhafazadaki titizliği göstereceğini taahhüt eder.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>12.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme kapsamında Proje’nin ifasına
                ilişkin amaçlar için Kabul Eden Taraf ile kişisel veri paylaşılmak zorunda
                kalınırsa ilgili kişisel veriler, Kabul Eden Taraf’ça münhasıran işbu Sözleşme
                amaçları kapsamında</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}> başta 6698 sayılı Kanun
                    olmak üzere ilgili mevzuat ve Kişisel Verileri Koruma Kurulu kararlarına uygun
                    olarak</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}> işlene</span><span lang="TR">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>cektir.&nbsp;
                        Kişisel verilerin anılan kapsamın dışına herhangi bir şekilde işlenmesi
                        kesinlikle yasaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf’ın, hizmetlerin İfşa Eden
                Taraf’a sunulması kapsamında üçüncü taraf hizmetlerini kullanmasının zorunlu
                olması nedeni de dâhil olmak üzere herhangi bir nedenle kişisel verilerin yurt
                içinde veya yurt dışında üçüncü bir tarafa aktarılması, Taraflar arasında aksi
                yönde bir yazılı mutabakat sağlanmadığı sürece yasaktır. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, Sözleşme’nin sona ermesi
                ile birlikte kişisel verilerin kayıtlı bulunduğu her türlü medya ve ortamı İfşa
                Eden Taraf’a imza karşılığı teslim edecek ve akabinde kendi nezdindeki
                kayıtları silecek ve yok edecektir. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, kişisel verilere gerek
                kendisi gerekse üçüncü taraflarca yetkisiz bir şekilde erişilmesini ve kişisel
                verilerin kendisine aktarımı amacı dışında kullanılmasını engelleyecek şekilde</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                    kişisel verinin niteliğine uygun güvenlik düzeyini temin etmeye yönelik gerekli
                    tüm teknik ve idari</span><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}> tedbiri</span>&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;almakla
                        yükümlüdür. Kabul Eden Taraf, bu kapsamda alınacak önlemlerin her halükarda
                        yürürlükteki mevzuat</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>, Kişisel Verilerin
                            Korunması Kurumu tarafından yayınlanan Kişisel Veri Güvenliği Rehberi</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                    veya benzer alanlarda faaliyet gösteren basiretli bir tacir tarafından kendi
                    nezdinde saklanan kişisel verilerin güvenliği için alınan önlemlerden daha az
                    olmayacağını kabul ve beyan eder. İfşa Eden Taraf’ça Sözleşme kapsamında “özel
                    nitelikli kişisel veri” paylaşımının söz konusu olması halinde, söz konusu
                    veriler Kabul Eden Taraf’ça niteliklerine </span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>ve
                        "Özel Nitelikli Kişisel Verilerin İşlenmesinde Veri Sorumlularınca
                        Alınması Gereken Yeterli Önlemler" ile ilgili Kişisel Verileri Koruma
                        Kurulunun 31/01/2018 Tarihli ve 2018/10 Sayılı Kararı’na </span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>uygun
                            bir şekilde ek güvenlik önlemleri ve yetkilendirmelere tabi şekilde
                            korunacaktır. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme kapsamında İfşa Eden Taraf’ça
                izin verilmesi şartı ile kişisel verilerin Kabul Eden Taraf’ça üçüncü bir
                tarafa aktarımının söz konusu olduğu hallerde veri aktarımının güvenli bir
                şekilde sağlanmasından Kabul Eden Taraf sorumlu olacaktır. Kişisel verilere
                herhangi bir şekilde yetkisiz erişim gerçekleşmesi veya kişisel verilerin
                herhangi bir şekilde işbu Sözleşme’ye aykırı şekilde üçüncü taraflarca
                erişilebilir hale gelmesi durumunda Kabul Eden Taraf bu durumu derhal (aynı gün
                içerisinde) İfşa Eden Taraf’a bildirecek ve bu durumdan doğan zararın minimize
                edilmesi için İfşa Eden Taraf’ça talep edilen her türlü bilgi, belge ve desteği
                gecikmeksizin sağlayacaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, kişisel verilerin işlenmesi
                söz konusu olur ise bu işlemeye ilişkin olarak işbu Sözleşme kapsamında
                öngörülen hususlarda varsa alt yüklenicisi ve alt yüklenicisinin personelini
                yazılı bir şekilde bilgilendirmekle yükümlüdür. Bahsi geçen personelin bahsi
                geçen düzenlemelere aykırı davranması halinde oluşacak zararlardan Kabul Eden
                Taraf müşterek ve müteselsilen sorumlu olacaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, işbu Sözleşme kapsamında
                İfşa Eden Taraf’ça kendisine iletilen kişisel veriler ile ilgili olarak İfşa
                Eden Taraf’ın her türlü talimatına uymakla ve veri sahibi ilgili kişi
                taleplerini derhal yerine getirmekle yükümlüdür. </span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Haklı
                    ve makul</span>&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;bir nedenle aynı gün içerisinde yerine
                        getirilemeyen talepler, nedeni yazılı olarak bildirilerek takip eden iş günü
                        içerisinde yerine getirilecektir. Veri sahibi ilgili kişinin herhangi bir
                        şekilde doğrudan Kabul Eden Taraf’tan talepte bulunması halinde söz konusu
                        talebe ilişkin olarak derhal (her halükarda ertesi iş günü) İfşa Eden Taraf’a yazılı
                        bildirimde bulunulacak ve İfşa Eden Taraf’ın talep ve talimatları doğrultusunda
                        aksiyon alınacaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, Sözleşme kapsamındaki
                kişisel veri işlemeleri bakımından İfşa Eden Taraf ve Kişisel Verileri Koruma
                Kurulu denetimine tabi olduğunu kabul ve beyan eder. İfşa Eden Taraf, söz
                konusu denetim hakkını bizzat veya üçüncü bir kişi eliyle kullanabilecek veya
                Kabul Eden Taraf’tan söz konusu denetimi gerçekleştirmesini talep
                edebilecektir. Söz konusu denetimler neticesinde Kabul Eden Taraf’ın kişisel
                verilere ilişkin yükümlülüklerine aykırı davrandığının tespit edilmesi halinde,
                ilgili denetim masrafları Kabul Eden Taraf tarafından üstlenilecek olup İfşa
                Eden Taraf’</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>ı</span>&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>n bu
                    tutarları Kabul Eden Taraf’a yapılacak ödemelerden mahsup hakkı saklıdır. Kabul
                    Eden Taraf’ın denetime izin vermemesi veya kendisinden talep edilen denetimi
                    gerçekleştirmemesi halinde İfşa Eden Taraf, işbu Sözleşme’yi derhal geçerli
                    olmak üzere feshedebilecektir.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf, 6698 sayılı Kişisel
                Verilerin Korunması Kanunu ve Kişisel Verileri Koruma Kurulu kararları başta
                olmak üzere, kişisel verilerin korunması ile ilgili yürürlükte bulunan tüm
                düzenlemelere, usul ve esaslara uygun davranmakla yükümlüdür. Bahsi geçen
                düzenlemeler kapsamında meydana gelebilecek herhangi bir değişiklik veya
                güncelleme nedeniyle Kabul Eden Taraf’ın süreçlerinde bir değişiklik gerekmesi
                halinde, Kabul Eden Taraf söz konusu değişikliği en geç ilgili yeni/güncel
                düzenleme yürürlüğe girmeden evvel tamamlamakla yükümlüdür. Bahsi geçen düzenlemenin
                işbu Sözleşme kapsamında bir değişiklik gerektirmesi halinde ise, Taraflar
                Sözleşme’nin uygun şekilde tadilini gerçekleştirecektir. Değişiklik gereken
                Sözleşme hükmü, Taraflarca bu hususta bir aksiyon alınmamış olsa dahi yürürlük
                tarihi itibariyle güncel mevzuata uygun şekilde uygulanacaktır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR">&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İfşa Eden Taraf’ın, Kabul Eden Taraf’ça işbu
                Sözleşme’nin kişisel verilere ilişkin hükümlerinin ihlali de dahil olmak üzere
                işbu Sözleşme kapsamında herhangi bir zarara uğraması, yasal, idari veya cezai
                bir yaptırıma tabi tutulması ya da herhangi bir zararı tazminle mükellef
                kılınması halinde, söz konusu tutarlar Kabul Eden Taraf’a rücu edilecek ve ilk
                yazılı talepte tüm ferileri ile birlikte İfşa Eden Taraf’a ödenecektir. İfşa
                Eden Taraf’ın işbu maddede belirtilen tutarları varsa Kabul Eden Taraf’a
                yapacağı ödemelerden mahsup hakkı saklıdır.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>13.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İfşa Eden Taraf Gizli Bilgiler’i iyi niyet
                ile ifşa eder, ancak aşağıda ifşa edilen Gizli Bilgiler’in niteliği, doğruluğu
                ve bütünlüğü ile ilgili olarak açık ya da gizli hiçbir taahhüt ya da garanti
                vermez.&nbsp; İfşa Eden Taraf, bağlı kuruluşları, yöneticileri, yönetim kurulu
                üyeleri/müdürleri ve çalışanları, Kabul Eden Taraf’ın, Gizli Bilgiler’i kullanmasından
                ve bu bilgilere itimat etmesinden dolayı herhangi bir yükümlülük altına
                girmeyecektir ve Kabul Eden Taraf işbu Sözleşme ile, hile ve kasıtlı
                suiistimalden dolayı meydana gelmemesi kaydıyla, İfşa Eden Taraf’ı Gizli
                Bilgiler’de bulunan hatalar ve eksikler ile ilgili olarak tüm sorumluluk ve
                yükümlülükten ibra eder.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>14.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İfşa Eden Taraf’ın sahip olabileceği diğer
                haklarına ya da tazmin veya telafi yollarına halel getirmemek kaydıyla, Kabul
                Eden Taraf, kendisinin (ya da Kabul Eden Taraf’ın Gizli Bilgiler’i ifşa ettiği
                herhangi başka bir kişinin) işbu Sözleşme şartlarını ihlal etmesine karşılık,
                ilgili tazminatların yeterli bir telafi olamayacağını ve İfşa Eden Taraf’ın,
                işbu Sözleşme’nin gerçekleşmiş veya gerçekleşme tehlikesi bulunan ihlali
                hallerinde tedbir, aynen ifa veya başkaca hakkaniyete uygun telafi yollarını
                kullanma hakkına sahip olacağını kabul eder.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%", "border": "none" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif", "color": "black" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf’ın</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>,</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>
                kişisel verilere ilişkin hükümler de dahil olmak üzere işbu Sözleşmede taahhüt
                ettiği </span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>gizlilik </span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>yükümlülükler</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>in</span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>e
                    aykırı davranması</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}> ve/veya söz konusu iş ve davranışların
                        5237 sayılı Türk Ceza Kanunu kapsamında bilişim suçunu teşkil etmesi </span></span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;sonucunda,
                            İfşa Eden Taraf’ın kar ve itibar kaybı da olmak ve bunlarla sınırlı olmamak
                            şartıyla her türlü maddi</span><span lang="TR"><span style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>, </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>manevi,
                                doğrudan ve dolaylı her türlü zararını ve buna ek olarak zararın iki katı
                                tutarında bir cezai şartı İfşa Eden’in ilk talebinde İfşa Eden’e derhal nakden
                                ve defaten ödemeyi kabul ve taahhüt eder. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>15.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme’de açıkça belirtilmedikçe,
                Kabul Eden Taraf’a zımnen ya da herhangi başka bir şekilde hiçbir imtiyaz,
                yetki ya da hak verilmemektedir.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>16.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme ya da buradaki herhangi bir hak
                tamamen ya da kısmen devir konusu olamaz.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>17.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme imza tarihinden itibaren
                yürürlüğe girecek olup tarafların gizlilik yükümlülükleri süresiz olarak devam
                edecektir.</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>18.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme, Türk Hukukuna tabi olup,
                taraflar arasındaki uyuşmazlıklarda İstanbul Merkez Mahkemeleri ve İcra
                Daireleri yetkilidir.&nbsp; </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İşbu Sözleşme …/../2022&nbsp; tarihinde iki nüsha
                olarak imzalanarak yürürlüğe girmiştir. </span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><u><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>İfşa Eden Taraf</span></u></b></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>D-MARKET ELEKTRONİK HİZMETLER VE TİC. A.Ş.</span></b></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><u><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>Kabul Eden Taraf </span></u></b></p>
            <p className="MsoNormal" style={{ "marginLeft": "0cm", "WebkitTextAlign": "justify", "textAlign": "justify", "WebkitTextIndent": "-.1pt", "textIndent": "-.1pt", "lineHeight": "115%" }}><b><span lang="TR" style={{ "fontSize": "11.0pt", "lineHeight": "115%", "fontFamily": "\"Calibri\",sans-serif" }}>.......................&nbsp;</span></b></p>
        </div>

    );
}