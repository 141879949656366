import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import BarChartIcon from '@mui/icons-material/BarChart';
import PolicyIcon from '@mui/icons-material/Policy';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import Canvas from "../../Components/Canvas/Canvas"
import "./Summary.css";

export default function Summary(props) {
    const { t } = useTranslation();

    return (
        <div className="summary--container">
            <Grid className="summary--grid" container>
                <Grid  className="summary--grid--item" item xs={12} sm={10} md={5} >
                    <div className="summary--desc">
                        <span className="grey--caption">{t("summary_caption")} &lt;!--showSummary--&gt;</span>
                        <h1 className="summary--header">{t("summary_welcome")}</h1>
                        <div className="summary--row">
                            <div className="summary--content">
                                {t("summary_desc")}
                            </div>
                            <Button
                                className="summary--report--button"
                                component={Link}
                                to={"report"}
                                type="submit"
                                variant="outlined">
                                {t("summary_report_button")}
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid className="summary--canvas" >
                    <Canvas />
                </Grid>

            </Grid>
            <div className="steps--container">
                <span className="grey--caption">{t("steps_caption")} &lt;!--showSteps--&gt;</span>
                <h1 className="steps--main--header">{t("steps_desc")}</h1>
                <Grid className="steps--grid" container>
                    <Grid item xs={12} sm={10} md={3} >
                        <div className="ranking--step list--step">
                            <BarChartIcon className="step--icon" />
                            <h3 className="steps--title">
                                {t("step_one")}
                            </h3>
                            <h4 className="steps--title">
                                {t("step_one_header")}
                            </h4>
                            <p className="steps--desc">
                                {t("step_one_desc")}
                            </p>
                            <Button
                                component={Link}
                                to={"ranking"}
                                className="summary--step--button"
                                type="submit"
                                variant="text"
                                endIcon={<ArrowCircleRightIcon />}>
                                {t("step_one_button")}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10} md={3} >
                        <div className="ranking--step list--step">
                            <PolicyIcon className="step--icon" />
                            <h3 className="steps--title">
                                {t("step_two")}
                            </h3>
                            <h4 className="steps--title">
                                {t("step_two_header")}
                            </h4>
                            <p className="steps--desc">
                                {t("step_two_desc")}
                            </p>
                            <Button
                                component={Link}
                                to={"scope"}
                                className="summary--step--button"
                                type="submit"
                                variant="text"
                                endIcon={<ArrowCircleRightIcon />}>
                                {t("step_two_button")}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10} md={3} >
                        <div className="ranking--step list--step">
                            <SummarizeIcon className="step--icon" />
                            <h3 className="steps--title">
                                {t("step_three")}
                            </h3>
                            <h4 className="steps--title">
                                {t("step_three_header")}
                            </h4>
                            <p className="steps--desc">
                                {t("step_three_desc")}
                            </p>
                            <Button
                                component={Link}
                                to={"report"}
                                className="summary--step--button"
                                type="submit"
                                variant="text"
                                endIcon={<ArrowCircleRightIcon />}>
                                {t("step_three_button")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}