import { useTranslation } from 'react-i18next';
import "./NotFound.css"


export default function NotFound(props) {
    const { t } = useTranslation();

    return (
        <div className="content--main">
            <div className="notfound--container">
                <p>{t("page_notfound")}</p>
            </div>
        </div>
    );
}