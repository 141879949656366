import React from 'react';
import './StepperContent.css';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';


export default function StepperContent(props) {
    const { t } = useTranslation();

    return (
        <>
            <h4>{t(`stepper_content.${props.activeStep}.title`)}</h4>
            <p>{t(`stepper_content.${props.activeStep}.description`)}</p>
            {t("stepper_option_select")}
            <br />
            <br />
            <ToggleButtonGroup
                color="primary"
                value={props.value}
                exclusive
                onChange={(event, value) => props.handleChange(event, value)}>
                {
                    t(`stepper_content.${props.activeStep}.toggleButtons`, { returnObjects: true }).map(buttonData => {
                        return (
                            <ToggleButton value={buttonData.value} key={buttonData.value}>
                                {buttonData.buttontext}
                                <Tooltip title={buttonData.tooltipText}>
                                    <InfoIcon />
                                </Tooltip>
                            </ToggleButton>
                        );
                    })
                }
            </ToggleButtonGroup>
        </>
    );
}