import React from "react";
import { useTranslation } from 'react-i18next';
import EnClarification from "../../assets/locale/en/ClarificationText"
import TrClarification from "../../assets/locale/tr/ClarificationText"

export default function Clarification(props) {
    const { i18n } = useTranslation();
    if (i18n.language === "tr") {
        return <TrClarification />
    }
    return <EnClarification />
}