import React from "react";
import { useTranslation } from 'react-i18next';
import { Link as MuiLink, AppBar, Toolbar, Drawer, CssBaseline, Box, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/cyberbounty_logo.png";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import "./Navbar.css";


export default function Navbar(props) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (value) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(value)
    };

    return (
        <>
            <Box className="navbar--mobile">
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={toggleDrawer(true)}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <NavLink className={"navbar--link--home"} to="">
                            <img className="navbar--mobile--logo" src={logo} alt="sample--img"></img>
                        </NavLink>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                >
                    <div className="drawer--header">
                        <IconButton onClick={toggleDrawer(false)}>
                            <ChevronLeftIcon className="drawer--left--icon" />
                        </IconButton>
                    </div>
                    <Divider />
                    <NavLink className="drawer--link" to=""> {t("homepage")} </NavLink>
                    <NavLink className="drawer--link" to="ranking"> {t("ranking")}</NavLink>
                    <NavLink className="drawer--link" to="scope"> {t("scope")}</NavLink>
                    <NavLink className="drawer--link" to="report"> {t("report")}</NavLink>
                    <NavLink className="drawer--link" to="about"> {t("about")}</NavLink>
                    <Divider />
                    <div className="language--bar--mobile">
                        <MuiLink
                            className="language--link"
                            onClick={() => i18n.changeLanguage("en")}
                            underline="hover">
                            EN
                        </MuiLink>
                        <span className="language--seperator">&nbsp;|&nbsp;</span>
                        <MuiLink
                            className="language--link"
                            onClick={() => i18n.changeLanguage("tr")}
                            underline="hover">
                            TR
                        </MuiLink>
                    </div>
                </Drawer>
            </Box>
            <AppBar className="navbar--main" component={"nav"}>
                <Toolbar className="navbar--content">
                    <NavLink className={"navbar--link--home"} to="">
                        <img src={logo} width="200" height="13" alt="sample--img"></img>
                    </NavLink>
                    <NavLink className="navbar--link" to="ranking"> {t("ranking")}</NavLink>
                    <NavLink className="navbar--link" to="scope"> {t("scope")}</NavLink>
                    <NavLink className="navbar--link" to="report"> {t("report")}</NavLink>
                    <NavLink className="navbar--link" to="about"> {t("about")}</NavLink>
                    <div className="language--bar">
                        <MuiLink
                            className="language--link"
                            onClick={() => i18n.changeLanguage("en")}
                            underline="hover">
                            EN
                        </MuiLink>
                        <span className="language--seperator">&nbsp;|&nbsp;</span>
                        <MuiLink
                            className="language--link"
                            onClick={() => i18n.changeLanguage("tr")}
                            underline="hover">
                            TR
                        </MuiLink>
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>

    );
}