import { useTranslation } from 'react-i18next';

export default function Ranking() {
    const { t } = useTranslation();

    return (
        <div className="content--main">
            <h3>{t("ranking_header")}</h3>
            {t("ranking_desc")}
            <br /><br />
            <h4>P1: {t("critical")} (CVSS {">="} 9.0)</h4>
            <div className="content--list">
                {t("critical_desc")}
            </div><br />
            <h4>P2: {t("high")} (CVSS 7.0 - 8.9)</h4>
            <div className="content--list">
                {t("high_desc")}
            </div><br />
            <h4>P3:  {t("moderate")} (CVSS 5.0 - 6.9)</h4>
            <div className="content--list">
                {t("moderate_desc")}
            </div><br />
            <h4>P4:  {t("low")} (CVSS {"<"} 5.0)</h4>
            <div className="content--list">
                {t("low_desc")}
            </div><br />
            <h4>P5:  {t("acceptable")}</h4>
            <div className="content--list">
                {t("acceptable_desc")}
            </div><br />
        </div>
    );
}