const content =
{
   ranking_desc: `Bildirilen tüm güvenlik açıklarının geçerliliği kontrol edilir, 
    sıralanır ve ardından Hepsiburada AppSec ekibi tarafından incelenir. Güvenlik açıkları, 
    CVSS Hesaplayıcısı’nın yardımıyla aşağıdaki yönergeler kullanılarak sıralanır. Bir güvenlik açığı 
    için son sıralama Hepsiburada AppSec Ekibinin takdirindedir.`,
   ranking_header: "Zafiyet Sıralama",
   cvss_calc: "CVSS Hesaplayıcısı",
   critical: "Kritik",
   high: "Yüksek",
   moderate: "Orta",
   low: "Düşük",
   acceptable: "Kabul Edilebilir",
   homepage:"Anasayfa",
   critical_desc: `Platforma ayrıcalık yükselmesine neden olan güvenlik açıkları. 
    Uzaktan kod yürütülmesine, finansal hırsızlığa, PII’ye büyük ölçekli erişime 
    vb. izin verir. Appsec Team’in takdirine bağlı olarak, kritik bir zafiyet gösteren 
    güvenlik açıkları, bilgi güvenliğine yönelik yaygın risk daha büyük bir ödül almaya hak 
    kazanabilir. Örnek: RCE, IDOR, SSRF, XXE, SQL Injection, Authentication Bypass vb.`,
   high_desc: `Süreçler de dahil olmak üzere platformun güvenliğini 
   etkileyen güvenlik açıkları. Örnek: Lateral authentication bypass, Stored XSS, etkiye bağlı olarak bazı CSRF zafiyetleri vb.`,
   moderate_desc: `Birden çok kullanıcıyı etkileyen ve çok az veya hiç kullanıcı 
   etkileşimi gerektirmeyen güvenlik açıkları. Örnek: Reflected XSS, URL Redirect vb.`,
   low_desc: `Tekil kullanıcıları etkileyen ve etkileşim gerektiren 
   veya tetiklemek için önkoşullar gerektiren açıklar (MITM). Örnek: Ayrıntılı hata ayıklama bilgileri vb.`,
   acceptable_desc: `Sömürülemeyen zayıflıklar ve düzeltilmeyen güvenlik açıkları. Örnek:Tasarım gereği olan sorunlar vb.`,
   domain_desc: `Aşağıdaki alanlar bu programa dahildir.`,
   domain_url: `www.hepsiburada.com (e-ticaret sayfaları)`,
   exclusion_header: `Kapsam Dışılar`,
   exclusion_desc: `Aşağıdaki rapor kategorileri programımızın kapsamı dışındadır ve
    Hepsiburada tarafından bu kapsamlara ilişkin herhangi bir ödül VERİLMEYECEKTİR:`,
   exclusion_note1: `CVSS 3 puanı 4.0'dan düşük olan herhangi bir güvenlik açığı, 
   aksi takdirde daha yüksek bir puan elde etmek için diğer güvenlik açıklarıyla birleştirilebilir.`,
   exclusion_note2: `Kaba kuvvet, DoS, DDoS, kimlik avı, metin enjeksiyonu veya sosyal mühendislik saldırıları.`,
   exclusion_note3: `Önemli bir güvenlik etkisi gösteren PoC olmadan XML-RPC dosyasının kullanılabilirliği.`,
   exclusion_note4: `WordPress eklentilerindeki güvenlik açıkları.`,
   exclusion_note5: `Self-XSS.`,
   exclusion_note8: `Resimler ve videolar gibi pasif varlıklar için karışık içerik uyarıları.`,
   exclusion_note9: `Minimum güvenlik etkileriyle tıklama hırsızlığı.`,
   exclusion_note10: `Yalnızca güncel olmayan tarayıcıları etkileyen kritik olmayan sorunlar.`,
   exclusion_note11: `Minimum güvenlikle Siteler Arası İstek Sahteciliği (CSRF) etkileri (Oturum açma/çıkış/kimliği doğrulanmamış).`,
   exclusion_note14: `Hassas olmayan çerezlerde eksik çerez bayrakları.`,
   exclusion_note15: `Sömürülemeyen güvenlik açıkları ve ihlallere ilişkin raporlar “en iyi uygulamalar” 
   (ör. HTTP güvenlik başlıklarının eksikliği (CSP, X-XSS, vb).`,
   exclusion_note16: `Oturum dışı çerezlerde güvenli/yalnızca HTTP işaretlerinin olmaması.`,
   exclusion_note17: `Dahili, gizli içermeyen sunucu hata mesajları veya kısıtlı veri veya onu elde etmek için yollar.`,
   exclusion_note18: `DNS kaydı yapılandırması (SPF, DKIM, DMARC, CAA, DNSSEC, vb).`,
   exclusion_note19: `Bir PoC ile önemli bir güvenlik etkisi gösteremeyeceğiniz teorik güvenlik açıkları.`,
   exclusion_note20: `Bazı formlarda hız sınırlama / kaba kuvvet koruması yoktur. Bunu programın kapsamı dışında değerlendireceğiz.`,
   exclusion_note21: `Daha önce bildirilen bilinen sorunlar.`,
   change_header: `Programdaki Değişiklikler`,
   change_desc: `Bu programı herhangi bir zamanda güncelleyebilir veya askıya alabiliriz. Biz
    bu konudaki en son bilgiler için bu sayfayı periyodik olarak gözden geçirmenizi tavsiye ederiz. 
    Gönderilen tüm raporlar, yürürlükteki program koşulları kullanılarak işlenecektir.
    AppSec Ekibimiz gönderilen raporu inceleyecektir.`,
   change_date: `Bu Gizlilik Politikasının Yürürlük Tarihi: 01/03/2022`,
   cvss_desc: `Güvenlik açığını bildirmeden önce güvenlik açığının önem derecesini buradan hesaplamanız gerekir.
    Her seçenek kendini açıklayıcıdır, açıklamayı görmek için imleci bilgi simgesinin üzerine getirmeniz yeterlidir.
    Güvenlik açığının önem derecesini görmek için önce hesap makinesinin tüm adımlarını tamamlamanız gerekir.`,
   report_header: `Güvenlik Açığı Bildir`,
   report_desc: "Lütfen güvenlik açığını buradan bildirin.",
   about_summary_header: "Bugbounty Programı Nedir?",
   about_summary_desc: `Bugbounty programı, birçok websitenin, organizasyonların ve yazılım geliştiricilerinin 
       kurduğu, bulunan güvenlik açıkları ve zafiyetlerini bildirenlerin
    ödüllendirildiği bir program. Bu programların amacı, geliştiricilerin
       buglar'ı kullanıcılar farkına varmadan önce keşfetmek ve çözmek, bunlardan doğabilecek
      güvenlik zafiyetlerini engellemektir. Örneğin bugbounty programları Mozilla, Facebook 
       Yahoo!, Google, Reddit, Square, Microsoft dahil 
       olmak üzere çok sayıda kuruluş tarafından uygulanmaktadır.`,
   summary_button: "Şimdi raporlayın",
   page_notfound: "Üzgünüz, aramaya çalıştığınız sayfa bulunamadı.",
   scope: "Kapsam",
   about: "Hakkında",
   report: "Bildir",
   ranking: "Sıralama",
   summary_caption: "Bug Avcıları Aranıyor",
   summary_welcome: "Hepsiburada CyberBounty'e Hoşgeldiniz",
   summary_desc: "AppSec ekibi olarak Hepsiburada'nın güvenliği için çalışıyoruz. Katkılarınızı bekliyoruz.",
   summary_report_button: "GÜVENLİK ZAFİYETİ RAPORLA",
   steps_caption: "Raporlamak İçin Adımlar",
   steps_desc: "Güvenlik Zafiyetlerini Raporlamak İçin 3 Adım",
   step_one: "1. Adım",
   step_one_header: "Sıralama",
   step_one_desc: "Zafiyetleri nasıl sınıflandırdığımızı inceleyin",
   step_one_button: "SINIFLANDIRMAYI İNCELE",
   step_two: "2. Adım",
   step_two_header: "Kapsam",
   step_two_desc: "Güvenlik zafiyetlerinin kapsamlarına bakın",
   step_two_button: "Kapsamlara bakın",
   step_three: "3. Adım",
   step_three_header: "Bildir",
   step_three_desc: "Bulduğunuz güvenlik zafiyetini bize bildirin",
   step_three_button: "BİLDİRİN",
   scope_header: "Kapsam",
   scope_desc: `Hepsiburada e-ticaret sistemi içerisinde kullanılan subdomainler kapsamımız dahilindedir.
    Bunun dışındaki subdomainler ile alakalı zafiyetler cyberbounty kapsamında değerlendirilmeyecektir.
    Aşağıda e-ticaret kapsamında yer alan subdomainler listelenmektedir: `,


};

export default content;