import { useTranslation } from 'react-i18next';

export default function TextfieldData() {
    const { t } = useTranslation();
    const textfieldData = [
        {
            className: "small--textfield",
            name: "vulnerabilityDate",
            type: "date",
            InputProps: { inputProps: { min: "2022-01-01" } }
        },
        {
            name: "title",
            label: t("label_title")
        },
        {
            name: "asset",
            label: t("label_asset"),
            placeholder: t("placeholder_asset")
        },
        {
            name: "cwe",
            label: t("label_cwe"),
            placeholder: t("placeholder_cwe")
        },
        {
            name: "summary",
            label: t("label_summary"),
            multiline: true,
            rows: 4
        },
        {
            name: "payload",
            label: t("label_payload"),
            multiline: true,
            rows: 4
        },
        {
            name: "reproduce",
            label: t("label_reproduce"),
            multiline: true,
            rows: 4
        },
        {
            name: "material",
            label: t("label_material"),
            multiline: true,
            rows: 4
        },
        {
            name: "impact",
            label: t("label_impact"),
            multiline: true,
            rows: 4
        },
        {
            name: "remediation",
            label: t("label_remediation"),
            multiline: true,
            rows: 4
        },
        {
            name: "userName",
            label: t("label_name")
        },
        {
            name: "userEmail",
            label: t("label_email")
        },
        {
            name: "ipAddress",
            label: t("label_ip"),
            placeholder: t("placeholder_ip")
        }
    ];

    return textfieldData
}